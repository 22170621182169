/**
 * State that the FFP is in.
 */
export const FFPState = {
	ALL: -1,
	ACTIVE: 0,
	ARCHIVED: 1
};

/**
 * Enum used to filter ActionPlan
 */
export const FFPActionPlanFilter = {
	ALL: 0,
	HAS_ACTION_PLAN: 1,
	NO_ACTION_PLAN: 2
};

/**
 * Enum used to filter Recommendations
 */
export const FFPRecommendationFilter = {
	ALL: 0,
	HAS_RECOMMENDATION: 1, 
	NO_RECOMMENDATION: 2
};

export const FFPStateLabel: Map<number, string> = new Map([
	[FFPState.ALL, 'all'],
	[FFPState.ACTIVE, 'active'],
	[FFPState.ARCHIVED, 'archived']
]);
