<div style="width:100%; height:100%;">
	<uno-tab>
		@if (this.inspection !== null) {
			<uno-tab-section [active]="true" title="{{'inspection' | translate}}">
				<!-- Non Compliant Message Form -->
				@if ((this.inspection.status === status.TODO) && this.inspection.rejectedMessage !== null && this.inspection.rejectedMessage.length > 0) {
					<uno-form [editable]="false" [layout]="this.layout.rejected" [object]="this.inspection" #rejectedForm></uno-form>
				}
				<!-- Base Form -->
				<uno-form [layout]="this.layout.base" [object]="this.inspection" #baseForm></uno-form>
				<!-- Result Form -->
				@if (this.inspection.status >= status.TODO) {
					<uno-form [layout]="this.layout.result" [object]="this.inspection" #resultForm></uno-form>
				}
				<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
					<!-- Save / Create -->
					<uno-button (click)="this.update();">{{(this.createMode ? 'create' : 'save') | translate}}</uno-button>
					@if (!this.createMode) {
						<!-- Submit -->
						@if (this.inspection.status === status.TODO) {
							<uno-button (click)="this.update(true);" color="success">{{ 'submit' | translate}}</uno-button>
						}
						<!-- Approve / Reject -->
						@if (this.inspection.status === status.WAITING_APPROVAL) {
							<uno-button (click)="this.approve(true);" color="success">{{ 'approve' | translate}}</uno-button>
							<uno-button (click)="this.approve(false);" color="warning">{{ 'reject' | translate}}</uno-button>
						}
						<!-- Delete -->
						@if (userPermissions.REPAIR_INSPECTIONS_DELETE | hasPermissions) {
							<uno-button (click)="this.delete();" color="error">{{ 'delete' | translate}}</uno-button>
						}
					}
					<!-- Export Report -->
					@if (this.inspection.status === status.COMPLETED) {
						<uno-button (click)="this.exportRepairInspectionDOCX();">{{'exportReportDOCX' | translate}}</uno-button>
						<uno-button (click)="this.exportRepairInspectionPDF();">{{'exportReportPDF' | translate}}</uno-button>
					}
				</div>
			</uno-tab-section>
		}
		@if (this.repair) {
			<uno-tab-section title="{{'repair' | translate}}">
				<!-- Rejected message -->
				@if ((this.repair.status === status.PROPOSAL || this.repair.status === status.ON_GOING) && this.repair.rejectionMessage !== undefined && this.repair.rejectionMessage.length > 0) {
					<uno-form [editable]="false" [layout]="repairLayout.rejectionMessage" [object]="this.repair"></uno-form>
				}
				<!-- Base Form -->
				<uno-form [editable]="false" [layout]="repairLayout.base" [object]="this.repair"></uno-form>
				<!-- Proposal Form -->
				<uno-form [editable]="false" [layout]="repairLayout.proposal" [object]="this.repair"></uno-form>
				<!-- Handle Fluids Form -->
				<uno-form [editable]="false" [layout]="repairLayout.handleFluids" [object]="this.repair"></uno-form>
				<!-- Weldings -->
				<uno-form [editable]="false" [layout]="repairLayout.needWelding" [object]="this.repair"></uno-form>
				<!-- Non Destructive Tests form -->
				@if (this.repair.nonDestructiveTests.length > 0) {
					<uno-form [editable]="false" [layout]="repairLayout.nonDestructiveTests" [object]="this.repair"></uno-form>
				}
				<!-- Proposal Approval Form -->
				@if (this.repair.status >= status.WAITING_PROPOSAL_APPROVAL) {
					<uno-form [editable]="false" [layout]="repairLayout.proposalApproval" [object]="this.repair"></uno-form>
				}
				<!-- Blocked Form -->
				@if (this.repair.status === status.BLOCKED || (this.repair.status >= status.ON_GOING && this.repair.blockedJustification !== '')) {
					<uno-form [editable]="false" [layout]="repairLayout.blocked" [object]="this.repair"></uno-form>
				}
				<!-- Job Form -->
				@if (this.repair.status >= status.ON_GOING && this.repair.status < status.BLOCKED) { <uno-form [editable]="false" [layout]="repairLayout.job" [object]="this.repair"></uno-form>
				}
				<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
					<uno-button (click)="app.navigator.navigate('/menu/repairs/works/edit', {uuid: this.repair.uuid});">{{ 'view' | translate}}</uno-button>
				</div>
			</uno-tab-section>
		}
		@if (this.asset) {
			<uno-tab-section title="{{'asset' | translate}}">
				<!-- Asset -->
				<uno-form [editable]="false" [layout]="assetLayout" [object]="this.asset"></uno-form>
				<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
					<uno-button (click)="app.navigator.navigate('/menu/asset-portfolio/asset/edit', {uuid: this.asset.uuid});">{{ 'view' | translate}}</uno-button>
				</div>
			</uno-tab-section>
		}
	</uno-tab>
</div>
