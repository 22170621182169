import {SortDirection} from 'src/app/utils/sort-direction';
import {EnumUtils} from 'src/app/utils/enum-utils';
import {Repair} from 'src/app/models/repairs/repairs/repair';
import {DateFrequency, DateFrequencyPrecision} from '../../../models/date-frequency';
import {UnoFormField} from '../../../components/uno-forms/uno-form/uno-form-field';
import {UnoFormFieldTypes} from '../../../components/uno-forms/uno-form/uno-form-field-types';
import {InputOptionsMultipleBatchRequest, InputOptionsMultipleLazyPageRequest} from '../../../components/uno-input/uno-options-lazy/uno-options-lazy.component';
import {Service} from '../../../http/service';
import {ServiceList} from '../../../http/service-list';
import {Session} from '../../../session';
import {CalendarEventTriggerDirection, CalendarEventTriggerDirectionLabel} from '../../../models/asset-planning/calendar-event-trigger-direction';
import {CalendarEventAlarmType, CalendarEventAlarmTypeLabel} from '../../../models/asset-planning/calendar-event-alarm-type';
import {CalendarEventSubtypes, CalendarEventSubtypesLabel, CalendarEventTypes, CalendarEventTypesLabel, CalendarEventTypeSubtypes} from '../../../models/asset-planning/calendar-event-actions';
import {RepairStatus} from '../../../models/repairs/repairs/repair-status';
import {AssetService} from '../../asset-portfolio/services/asset.service';
import {CalendarEventPeriodicityModes, CalendarEventPeriodicityModesLabel} from '../../../models/asset-planning/calendar-event-periodicity-modes';
import {InspectionProjectService} from '../../inspections/services/inspection-project.service';
import {RepairService} from '../../repairs/repair-work/services/repair.service';

export const CalendarEventLayout: UnoFormField[] = [
	{
		label: 'overall',
		type: UnoFormFieldTypes.SUB_FORM,
		expanded: true,
		fields: [
			{
				required: false,
				label: 'name',
				attribute: 'name',
				type: UnoFormFieldTypes.TEXT
			},
			{
				required: false,
				attribute: 'description',
				label: 'description',
				type: UnoFormFieldTypes.TEXT_MULTILINE
			}
		]
	},
	{
		label: 'actionType',
		type: UnoFormFieldTypes.SUB_FORM,
		expanded: true,
		fields: [
			{
				required: true,
				attribute: 'actionType',
				label: 'actionType',
				type: UnoFormFieldTypes.OPTIONS,
				options: Object.values(CalendarEventTypes).map((value) => {
					return {label: CalendarEventTypesLabel.get(value), value: value};
				}),
				editable: (object: any, row: UnoFormField) => {
					return !object.uuid;
				}
			},
			{
				required: true,
				attribute: 'actionSubtype',
				label: 'actionSubtype',
				type: UnoFormFieldTypes.OPTIONS,
				options: [],
				editable: (object: any, row: UnoFormField) => {
					return object.actionType && !object.uuid;
				},
				fetchOptions: function(object: any, row: UnoFormField) {
					const options = [];
					if (CalendarEventTypeSubtypes.has(object.actionType)) {
						const subtypes = CalendarEventTypeSubtypes.get(object.actionType);
						for (let i = 0; i < subtypes.length; i++) {
							options.push({label: CalendarEventSubtypesLabel.get(subtypes[i]), value: subtypes[i]});
						}
					}
					row.options = options;
				}
			},
			{
				required: true,
				attribute: 'inspectionProjectUuid',
				label: 'inspectionProject',
				multiple: false,
				type: UnoFormFieldTypes.OPTIONS_MULTIPLE_LAZY,
				identifierAttribute: 'uuid',
				isActive: (object: any, row: UnoFormField) => {
					return object.actionSubtype === CalendarEventSubtypes.ASSET_DYNAMIC_INSPECTION;
				},
				fetchOptionsLazy: async function(request: InputOptionsMultipleLazyPageRequest): Promise<void> {
					const data = {
						from: request.from,
						count: request.count,
						search: request.search,
						sortField: '[inspection_project].[name]',
						sortDirection: SortDirection.ASC
					};
		
					try {
						const req = await InspectionProjectService.list(data);
						request.onFinish(req.projects, req.hasMore, req.id);
					} catch {
						request.onError();
					}
				},
				fetchOptionsBatch: async function(request: InputOptionsMultipleBatchRequest): Promise<void> {
					if (request.options.length > 0) {
						const data = {uuid: request.options[0]};
						const req = await Service.fetch(ServiceList.inspection.project.get, null, null, data, Session.session);
						request.onFinish([req.response.project]);
					} else {
						request.onFinish([]);
					}
				},
				getOptionText: function(option: any): string {
					return (option.name ? option.name : '') + (option.description ? ' - ' + option.description : '');
				}
			},
			{
				required: true,
				attribute: 'assetUuid',
				label: 'asset',
				multiple: false,
				type: UnoFormFieldTypes.OPTIONS_MULTIPLE_LAZY,
				identifierAttribute: 'uuid',
				isActive: (object: any, row: UnoFormField) => {
					return object.actionSubtype === CalendarEventSubtypes.ASSET_DYNAMIC_INSPECTION || object.actionSubtype === CalendarEventSubtypes.ASSET_ATEX_INSPECTION || object.actionSubtype === CalendarEventSubtypes.REPAIR_DEFINITIVE_REPAIR || object.actionSubtype === CalendarEventSubtypes.ASSET_DL50_INSPECTION;
				},
				fetchOptionsLazy: async function(request: InputOptionsMultipleLazyPageRequest): Promise<void> {
					const data = {
						from: request.from,
						count: request.count,
						search: request.search,
						searchFields: ['[ap_asset].[id]', '[ap_asset].[name]', '[ap_asset].[tag]'],
						sortField: '[ap_asset].[name]',
						sortDirection: SortDirection.ASC
					};
		
					try {
						const req = await Service.fetch(ServiceList.assetPortfolio.asset.list, null, null, data, Session.session);
						request.onFinish(req.response.assets, req.response.hasMore, req.id);
					} catch {
						request.onError();
					}
				},
				fetchOptionsBatch: async function(request: InputOptionsMultipleBatchRequest): Promise<void> {
					if (request.options.length > 0) {
						const asset = await AssetService.get(request.options[0]);
						request.onFinish([asset]);
					} else {
						request.onFinish([]);
					}
				},
				getOptionText: function(option: any): string {
					return (option.name ? option.name : '') + (option.tag ? (option.name ? ' - ' : '') + option.tag : '') + (option.description ? (option.tag || option.name ? ' - ' : '') + option.description : '');
				}
			},
			{
				required: true,
				attribute: 'repairUuid',
				label: 'repair',
				multiple: false,
				type: UnoFormFieldTypes.OPTIONS_MULTIPLE_LAZY,
				identifierAttribute: 'uuid',
				isActive: (object: any, row: UnoFormField) => {
					return object.actionSubtype === CalendarEventSubtypes.REPAIR_TEMPORARY_INSPECTION;
				},
				fetchOptionsLazy: async function(request: InputOptionsMultipleLazyPageRequest): Promise<void> {
					const data = {
						status: RepairStatus.ALL,
						from: request.from,
						count: request.count,
						search: request.search,
						searchFields: ['[repair].[id]', '[repair].[description]', '[ap_asset].[id]', '[ap_asset].[name]', '[ap_asset].[tag]'],
						sortField: '[ap_asset].[name]',
						sortDirection: SortDirection.ASC
					};
		
					try {
						const req = await Service.fetch(ServiceList.repairs.list, null, null, data, Session.session);
						request.onFinish(req.response.repairs, req.response.hasMore, req.id);
					} catch {
						
						request.onError();
					}
				},
				fetchOptionsBatch: async function(request: InputOptionsMultipleBatchRequest): Promise<void> {
					if (request.options.length > 0) {
						const repair: Repair = await RepairService.get(request.options[0]);
						request.onFinish([repair]);
					} else {
						request.onFinish([]);
					}
				},
				getOptionText: function(option: any): string {
					return 	(option.description ? option.description : '') + (option.asset.tag ? (option.description ? ' - ' : '') + option.asset.tag : '') + (option.asset.name ? ' (' + option.asset.name + ')' : '') ;
				}
			}
		]
	},
	{
		label: 'alarms',
		type: UnoFormFieldTypes.SUB_FORM,
		expanded: true,
		fields: [
			{
				required: false,
				attribute: 'alarmType',
				label: 'alarmType',
				type: UnoFormFieldTypes.OPTIONS_MULTIPLE,
				options: Object.values(CalendarEventAlarmType).map((value) => {
					return {label: CalendarEventAlarmTypeLabel.get(value), value: value};
				})
			},
			{
				required: false,
				attribute: 'userUuids',
				label: 'users',
				type: UnoFormFieldTypes.USER_SELECTOR,
				multiple: true,
				isActive: (object: any, row: UnoFormField) => {
					return object.alarmType.length !== 0;
				}
			},
			{
				required: false,
				attribute: 'teamUuids',
				label: 'teams',
				type: UnoFormFieldTypes.TEAM_SELECTOR,
				multiple: true,
				isActive: (object: any, row: UnoFormField) => {
					return object.alarmType.length !== 0;
				}
			},
			{
				required: true,
				isActive: (object: any, row: UnoFormField) => {
					return object.alarmType.length !== 0;
				},
				attribute: 'alarmOffset',
				label: 'alarmOffset',
				dateFrequencyPrecision: DateFrequencyPrecision.HOUR,
				type: UnoFormFieldTypes.DATE_FREQUENCY
			}
		]
	},
	{
		label: 'date',
		type: UnoFormFieldTypes.SUB_FORM,
		expanded: true,
		fields: [
			{
				required: false,
				attribute: 'triggerEvent',
				label: 'triggerEvent',
				type: UnoFormFieldTypes.CHECKBOX
			},
			{
				required: true,
				attribute: 'triggerEventDirection',
				label: 'direction',
				isActive: (object: any, row: UnoFormField) => {
					return object.triggerEvent;
				},
				type: UnoFormFieldTypes.OPTIONS,
				options: Object.values(CalendarEventTriggerDirection).map((value) => {
					return {label: CalendarEventTriggerDirectionLabel.get(value), value: value};
				})
			},
			{
				required: true,
				attribute: 'triggerEventUuid',
				label: 'event',
				isActive: (object: any, row: UnoFormField) => {
					return object.triggerEvent;
				},
				multiple: false,
				type: UnoFormFieldTypes.OPTIONS_MULTIPLE_LAZY,
				identifierAttribute: 'uuid',
				fetchOptionsLazy: async function(request: InputOptionsMultipleLazyPageRequest): Promise<void> {
					const data = {
						from: request.from,
						count: request.count,
						search: request.search,
						searchFields: ['[calendar_event].[name]'],
						sortField: '[calendar_event].[name]',
						sortDirection: SortDirection.ASC
					};
		
					try {
						const req = await Service.fetch(ServiceList.assetPlanning.calendarEvent.list, null, null, data, Session.session);
						request.onFinish(req.response.calendarEvents, req.response.hasMore, req.id);
					} catch {
						request.onError();
					}
				},
				fetchOptionsBatch: async function(request: InputOptionsMultipleBatchRequest): Promise<void> {
					if (request.options.length > 0) {
						const data = {uuid: request.options[0]};
		
						const req = await Service.fetch(ServiceList.assetPlanning.calendarEvent.get, null, null, data, Session.session);
						request.onFinish([req.response.calendarEvent]);
					} else {
						request.onFinish([]);
					}
		
				},
				getOptionText: function(option: any): string {
					return (option.name ? option.name + ' - ' : '') + (option.description ? option.description : '');
				}
			},
			{
				required: true,
				attribute: 'triggerEventOffset',
				label: 'triggerEventOffset',
				dateFrequencyPrecision: DateFrequencyPrecision.HOUR,
				isActive: (object: any, row: UnoFormField) => {
					return object.triggerEvent;
				},
				type: UnoFormFieldTypes.DATE_FREQUENCY
			},
			{
				required: true,
				attribute: 'date',
				label: 'date',
				isActive: (object: any, row: UnoFormField) => {
					return !object.triggerEvent;
				},
				type: UnoFormFieldTypes.DATE
			},
			{
				required: false,
				attribute: 'duration',
				label: 'duration',
				dateFrequencyPrecision: DateFrequencyPrecision.MINUTE,
				isActive: (object: any, row: UnoFormField) => {
					return !object.triggerEvent;
				},
				type: UnoFormFieldTypes.DATE_FREQUENCY
			}
		]
	},
	{
		label: 'periodicity',
		type: UnoFormFieldTypes.SUB_FORM,
		expanded: true,
		fields: [
			{
				required: true,
				attribute: 'periodic',
				label: 'periodic',
				type: UnoFormFieldTypes.CHECKBOX,
				onChange: (object: any, row: UnoFormField) => {
					object.periodicity = new DateFrequency();
				}
			},
			{
				isActive: (object: any, row: UnoFormField) => {
					return object.periodic;
				},
				required: true,
				attribute: 'periodicityMode',
				label: 'mode',
				type: UnoFormFieldTypes.OPTIONS,
				options: EnumUtils.values(CalendarEventPeriodicityModes).map((value) => {
					return {label: CalendarEventPeriodicityModesLabel.get(value), value: value};
				}),
				onChange: (object: any, row: UnoFormField) => {
					object.periodicityRepetition = null;
					object.periodicityEndDate = new Date();
				}
			},
			{
				required: true,
				isActive: (object: any, row: UnoFormField) => {
					return object.periodic;
				},
				dateFrequencyPrecision: DateFrequencyPrecision.HOUR,
				attribute: 'periodicity',
				label: 'periodicity',
				type: UnoFormFieldTypes.DATE_FREQUENCY
			},
			{
				required: true,
				isActive: (object: any, row: UnoFormField) => {
					return object.periodicityMode === CalendarEventPeriodicityModes.REPETITIONS && object.periodic;
				},
				isEmpty: (object, row) => {
					return !object.periodicityRepetition;
				},
				attribute: 'periodicityRepetition',
				label: 'numberTimesRepeat',
				type: UnoFormFieldTypes.NUMBER
			},
			{
				required: true,
				isActive: (object: any, row: UnoFormField) => {
					return object.periodicityMode === CalendarEventPeriodicityModes.END_DATE && object.periodic;
				},
				attribute: 'periodicityEndDate',
				label: 'endDate',
				type: UnoFormFieldTypes.DATE
			}
		]
	}
];

