import {Resource} from '../../resource';
import {UUID, UUIDIdentification} from '../../uuid';
import {RepairInspectionStatus} from './repair-inspection-status';
import {RepairInspectionResult} from './repair-inspection-result';

export class RepairInspection extends UUIDIdentification {
	/**
	 * Date when this inspection was performed.
	 */
	public date: Date = null;

	/**
	 * The repair UUID this inspection is associated to
	 */
	public repairUuid: UUID = null;

	/**
	 * Inspection number.
	 */
	public inspectionNumber: string = '';

	/**
	 * The UUID of the team this inspection is associated to
	 */
	public teamUuid: UUID = null;

	/**
	 * The date this repair should be performed
	 */
	public dueDate: Date = null;

	/**
	 * Status of the repair.
	 */
	public status: number = RepairInspectionStatus.NONE;

	/**
	 * Inspection result evaluated by the repair inspector
	 */
	public result: number = RepairInspectionResult.NONE;

	/**
	 * Text notes pointed about the inspector
	 */
	public notes: string = '';

	/**
	 * Pictures of the repair inspection
	 */
	public pictures: Resource[] = [];

	/**
	 * Documents of repair inspection
	 */
	public documents: Resource[] = [];

	/**
	 * Inspection non compliant message
	 */
	public rejectedMessage: string = null;

	/**
	 * Parse the data of an API retrieved Repair Inspection object.
	 *
	 * @param data - Repair inspection object to parse data
	 */
	public static parse(data: RepairInspection): RepairInspection {
		const inspection = new RepairInspection();
			
		inspection.uuid = data.uuid;
		inspection.date = data.date ? new Date(data.date) : new Date();
		inspection.inspectionNumber = data.inspectionNumber;
		inspection.createdAt = new Date(data.createdAt);
		inspection.updatedAt = new Date(data.updatedAt);
		inspection.repairUuid = data.repairUuid;
		inspection.dueDate = new Date(data.dueDate);
		inspection.status = data.status;
		inspection.teamUuid = data.teamUuid;
		inspection.result = data.result;
		inspection.notes = data.notes;
		inspection.pictures = Resource.parseArray(data.pictures);
		inspection.documents = Resource.parseArray(data.documents);
		inspection.rejectedMessage = data.rejectedMessage;
		
		return inspection;
	}
}
