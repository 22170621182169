import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {IonicModule} from '@ionic/angular';
import {AsyncPipe} from '@angular/common';
import {InspectionWorkflowStepStatus, InspectionWorkflowStepStatusLabel} from 'src/app/models/inspections/workflow/inspection-workflow-step-status';
import {EnumUtils} from 'src/app/utils/enum-utils';
import {ScreenComponent} from '../../../../../components/screen/screen.component';
import {App} from '../../../../../app';
import {Session} from '../../../../../session';
import {UserPermissions} from '../../../../../models/users/user-permissions';
import {Service} from '../../../../../http/service';
import {ServiceList} from '../../../../../http/service-list';
import {UUID} from '../../../../../models/uuid';
import {InspectionWorkflowStep} from '../../../../../models/inspections/workflow/inspection-workflow-step';
import {InspectionExport} from '../../../data/inspection/inspection-export';
import {InspectionProject} from '../../../../../models/inspections/project/inspection-project';
import {UnoCardComponent} from '../../../../../components/uno/uno-card/uno-card.component';
import {UnoNoDataComponent} from '../../../../../components/uno/uno-no-data/uno-no-data.component';
import {InspectionStepsGraphComponent} from '../../../components/inspection-steps-graph/inspection-steps-graph.component';
import {UnoButtonComponent} from '../../../../../components/uno/uno-button/uno-button.component';
import {UnoContentComponent} from '../../../../../components/uno/uno-content/uno-content.component';
import {PermissionsPipe} from '../../../../../pipes/permissions.pipe';

/**
 * Auxiliary class to store the count of each inspection step.
 */
class InspectionListStepCount extends InspectionWorkflowStep {
	public count: Promise<number>;
}

@Component({
	selector: 'inspection-list-steps-page',
	templateUrl: './inspection-list-steps.page.html',
	standalone: true,
	imports: [
		UnoContentComponent,
		UnoButtonComponent,
		IonicModule,
		InspectionStepsGraphComponent,
		UnoNoDataComponent,
		UnoCardComponent,
		AsyncPipe,
		TranslateModule,
		PermissionsPipe
	]
})
export class InspectionListStepsPage extends ScreenComponent implements OnInit {
	public app: any = App;

	public session: any = Session;

	public userPermissions: any = UserPermissions;

	public inspectionWorkflowStepStatusLabel: any = InspectionWorkflowStepStatusLabel;

	public permissions = [UserPermissions.INSPECTION];

	/**
	 * Project UUID to list inspections for.
	 */
	public projectUuid: UUID = null;

	/**
	 * Steps used by the project.
	 */
	public steps: InspectionListStepCount[] = [];

	/**
	 * Global count of how many inspection there are in the project.
	 */
	public count: Promise<number>;

	/**
	 * Status of the steps to be displayed.
	 */
	public status: number[] = EnumUtils.values(InspectionWorkflowStepStatus);

	/**
	 * Steps by their status.
	 */
	public stepsByStatus: Map<number, InspectionListStepCount[]> = new Map();

	public constructor(public activeRoute: ActivatedRoute) {
		super();
	}

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();

		const loadData = async(): Promise<void> => {
			this.projectUuid = null;
			this.steps = [];

			const data = App.navigator.getData();
			if (!data || !data.project) {
				App.navigator.pop();
				return;
			}

			App.navigator.setTitle('inspections');

			this.projectUuid = data.project;
			this.count = this.countStep(this.projectUuid);

			if (this.projectUuid) {
				const request = await Service.fetch(ServiceList.inspection.project.get, null, null, {uuid: this.projectUuid}, Session.session, true);
				const project = InspectionProject.parse(request.response.project);
				if (project.name) {
					App.navigator.setTitle(project.name);
				}
			}

			await this.loadSteps(this.projectUuid);

		};

		await loadData();

		this.activeRoute.queryParams.subscribe(loadData);
	}

	/**
	 * Load the list of steps to be displayed in the screen.
	 */
	public async loadSteps(projectUuid: UUID): Promise<void> {
		const req = await Service.fetch(ServiceList.inspection.workflowStep.list, null, null, {project: projectUuid}, Session.session);

		// @ts-ignore
		this.steps = InspectionWorkflowStep.parseArray(req.response.steps);
		for (let i = 0; i < this.steps.length; i++) {
			this.steps[i].count = this.countStep(projectUuid, this.steps[i].uuid);
		}

		// Sort steps by its index
		this.stepsByStatus = new Map();
		for (const status of this.status) {
			this.stepsByStatus.set(status, this.steps.filter((step: InspectionListStepCount) => {
				return step.status === status;
			}).sort((a: InspectionWorkflowStep, b: InspectionWorkflowStep) => {
				return a.index - b.index;
			}));
		}
	}

	/**
	 * Count how many inspection there are in a specific step.
	 *
	 * @param projectUuid - Project to count inspections.
	 * @param step - Step to be checked.
	 */
	public async countStep(projectUuid: UUID, step: UUID = null): Promise<number> {
		const request = await Service.fetch(ServiceList.inspection.count, null, null, {projectUuid: projectUuid, stepUuid: step}, Session.session);
		return request.response.count;
	}

	/**
	 * Export all the inspections of this project in a XLSX file.
	 * 
	 * A new inspection per line and a new column per each form fiel on the inspection project.
	 */
	public async exportProjectInspectionsXLSX(): Promise<void> {
		await InspectionExport.exportXLSX([this.projectUuid]);
	}


}

