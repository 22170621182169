@if (this.team !== null) {
	<uno-content [borders]="true">
		<uno-form [editable]="this.canEdit()" [layout]="this.layout" [object]="this.team" #teamForm></uno-form>
		<div style="display: flex; flex-direction: row-reverse; gap: 5px;">
			<!-- Save / Create -->
			<uno-button (click)="this.update();">{{(this.createMode ? 'create' : 'save') | translate}}</uno-button>
			<!-- Update / Delete -->
			@if (!this.createMode) {
				@if ([userPermissions.TEAM_EDIT] | hasPermissions) {
					<uno-button (click)="this.update(true);">{{'update' | translate}}</uno-button>
				}
				@if ([userPermissions.TEAM_DELETE] | hasPermissions) {
					<uno-button (click)="this.delete();" color="error">{{'delete' | translate}}</uno-button>
				}
			}
		</div>
	</uno-content>
}
