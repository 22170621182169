import {
	Component,
	forwardRef,
	Input,
	OnChanges,
	SimpleChanges,
	ViewChild,
	ViewEncapsulation
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {IonPopover, IonicModule} from '@ionic/angular';
import {addHours} from 'date-fns';
import {CSSUtils} from 'src/app/utils/css-utils';
import {NameUtils} from 'src/app/utils/name-utils';
import {TranslateModule} from '@ngx-translate/core';
import {NgClass, NgStyle} from '@angular/common';
import {FormatDatePipe} from 'src/app/pipes/format-date.pipe';
import {App} from '../../../app';
import {Locale} from '../../../locale/locale';

/**
 * Type of date time mode can be 'date-time', 'date' or 'time' only.
 *
 * 'date-time' shows both date and time (day, month, year, hours, ...) selectors.
 *
 * 'time' displays only time (hours, minutes, ...) selectors.
 */
export type DateTimeMode = 'date-time' | 'date' | 'time' | 'month-year';

@Component({
	selector: 'uno-date-time',
	templateUrl: './uno-date-time.component.html',
	styleUrls: ['uno-date-time.component.css'],
	encapsulation: ViewEncapsulation.None,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => { return UnoDateTimeComponent; }),
			multi: true
		}
	],
	standalone: true,
	imports: [NgStyle, NgClass, IonicModule, TranslateModule]
})
export class UnoDateTimeComponent implements OnChanges, ControlValueAccessor {
	@ViewChild('popover', {static: true})
	public popover: IonPopover = null;

	public get app(): any {return App;}

	public get locale(): any {return Locale;}

	/**
	 * Maximum date to be used by default.
	 */
	public static maxDefault: Date = new Date(2100, 0, 0);

	/**
	 * Minimum date to be used by default.
	 */
	public static minDefault: Date = new Date(1900, 0, 0);

	/**
	 * Border to be displayed around the input.
	 */
	@Input()
	public border: boolean = true;

	/**
	 * Time selection mode.
	 */
	@Input()
	public mode: DateTimeMode = 'date-time';

	/**
	 * Allow the input to be disabled.
	 */
	@Input()
	public disabled: boolean = false;

	/**
	 * Placeholder text to be displayed when there is no date selected.
	 */
	@Input()
	public placeholder: string = '';

	/**
	 * The maximum date time the user can choose from.
	 */
	@Input()
	public max: Date = null;

	/**
	 * The minimum date time the user can choose from.
	 */
	@Input()
	public min: Date = null;

	/**
	 * Value currently selected.
	 */
	public value: Date = null;

	/**
	 * Backdrop for the component when the popover is opened.
	 */
	public backdrop: HTMLElement = null;

	/**
	 * Method called when the data is changed.
	 */
	public onChange: (value: Date)=> void = null;

	/**
 	 * Creates the backdrop for the date-time component.
 	 */
	public createBackdrop(): void {

		if (this.backdrop) {
			return;
		}

		this.backdrop = document.createElement('div');

		Object.assign(this.backdrop.style, {
			position: 'absolute',
			width: '100%',
			height: '100%',
			top: '0px',
			left: '0px',
			backgroundColor: CSSUtils.getVariable('--backdrop'),
			// @ts-ignore
			zIndex: parseInt(this.popover.el.style.zIndex) - 1
		});


		this.backdrop.onclick = () => {
			this.dateTimeClick();
		};
		document.body.appendChild(this.backdrop);
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (typeof this.value === 'string') {
			this.value = new Date(this.value);
		}

		if (typeof this.max === 'string') {
			this.max = new Date(this.max);
		}

		if (typeof this.min === 'string') {
			this.min = new Date(this.min);
		}
	}

	/**
	 * Open the calendar selector.
	 */
	public async openSelector(event: MouseEvent): Promise<void> {
		if (this.disabled) {
			return;
		}
		this.popover.reference = 'event';
		this.popover.showBackdrop = false;

		await this.popover.present(event);
		this.popover.ionPopoverWillDismiss.subscribe(() => {
			this.removeBackdrop();
		});

		this.createBackdrop();
	}

	/**
	 * Get the text to be displayed to preview the date.
	 */
	public displayText(): string {
		if (this.value) {
			if (this.mode === 'date-time') {
				return FormatDatePipe.formatDateTime(this.value);
			} else if (this.mode === 'date') {
				return FormatDatePipe.formatDate(this.value);
			} else if (this.mode === 'time') {
				return FormatDatePipe.formatTime(this.value);
			} else if (this.mode === 'month-year') {
				return NameUtils.camelToPascal(this.value.toLocaleDateString(Locale.code, {year: 'numeric', month: 'long'}));
			}
		}

		return this.placeholder;
	}

	/**
	 * Get the date in ISO8601 format.
	 */
	public get isoDate(): string {
		if (!this.value) {
			return undefined;
		}

		return this.value.toISOString();
	}

	/**
	 * Get the date in ISO8601 format with the Timezone Offset included.
	 */
	public get localeDate(): string {
		if (!this.value) {
			return undefined;
		}

		return addHours(this.value, -this.value.getTimezoneOffset() / 60).toISOString();
	}

	/**
	 * Get max date range in format YYYY-MM-DD
	 */
	public get maxDate(): string {
		if (!this.max) {
			return UnoDateTimeComponent.maxDefault.toISOString().substr(0, 10);
		}

		return this.max.toISOString().substr(0, 10);
	}

	/**
	 * Get min date range in format YYYY-MM-DD
	 */
	public get minDate(): string {
		if (!this.min) {
			return UnoDateTimeComponent.minDefault.toISOString().substr(0, 10);
		}

		return this.min.toISOString().substr(0, 10);
	}

	public registerOnChange(onChange: any): void {
		this.onChange = onChange;
	}

	public writeValue(value: string | Date): void {
		if (typeof value === 'string') {
			value = new Date(value);
		}

		this.value = value;

		if (this.onChange) {
			this.onChange(this.value);
		}
		this.removeBackdrop();
	}

	public registerOnTouched(fn: any): void {}

	public setDisabledState(disabled: boolean): void {
		this.disabled = disabled;
	}

	/**
	 * Removes the custom backdrop.
	 */
	public removeBackdrop(): void {
		if (this.backdrop) {
			this.backdrop.remove();
			this.backdrop = null;
		}
	}

	/**
	 * Closes the backdrop when any of the buttons or backdrop is clicked.
	 * 
	 * Also prevents the dismiss event propagation to the parent component.
	 */
	public dateTimeClick(): void {
		this.removeBackdrop();
		this.popover.dismiss(null, null, false);
	}
}
