import {Component, HostListener, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {IonicModule} from '@ionic/angular';
import {RouterOutlet} from '@angular/router';
import {NgStyle, NgClass} from '@angular/common';
import {Session} from '../../../session';
import {App} from '../../../app';
import {Environment} from '../../../../environments/environment';
import {ScreenComponent} from '../../../components/screen/screen.component';
import {StyleManager} from '../../../theme/style-manager';
import {LocaleList} from '../../../locale/locale';
import {CssNgStyle} from '../../../utils/css-ng-style';
import {UnoTextComponent} from '../../../components/uno/uno-text/uno-text.component';

@Component({
	selector: 'app-login',
	templateUrl: './login.page.html',
	styleUrls: ['./login.page.scss'],
	standalone: true,
	imports: [NgStyle, RouterOutlet, IonicModule, UnoTextComponent, NgClass, TranslateModule],
	encapsulation: ViewEncapsulation.None
})
export class LoginPage extends ScreenComponent implements OnInit, OnDestroy {
	public styleManager: any = StyleManager;

	public environment: any = Environment;

	public app: any = App;

	public localeList: any = LocaleList;

	public session: any = Session;

	public get height(): any { return document.body.offsetHeight; }

	/**
	 * Style applied to the background image element.
	 */
	public imageStyle: CssNgStyle = {};

	/**
	 * Style applied to the logo element.
	 */
	public logoStyle: CssNgStyle = {};

	@HostListener('document:mousemove', ['$event'])
	public parallaxEffect(event: MouseEvent): void {
		const x = event.clientX / document.body.offsetWidth;
		const y = event.clientY / document.body.offsetHeight;

		this.imageStyle.top = -y * 20 + 'px';
		this.imageStyle.left = -x * 20 + 'px';
	}

	public ngOnInit(): void {
		super.ngOnInit();

		// Check if the user already has a session loaded
		if (Session.isValid()) {
			App.navigator.navigate('/menu');
		}
	}

	public ngOnDestroy(): void {
		super.ngOnDestroy();
	}

	/**
	 * Set language to be used.
	 *
	 * @param code - Locale code.
	 */
	public useLocale(code): void {
		Session.settings.locale = code;
		Session.updateSettings(Session.settings);
	}
}
