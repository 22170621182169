@if (this.object) {
	<div (click)="this.expanded.set(!this.expanded());" class="uno-form-collapsable-field-header">
		<div class="uno-form-collapsable-field-arrow">
			<ion-icon src="{{this.expanded() ? 'assets/icons/material/expand_more/outline.svg' : 'assets/icons/material/chevron_right/outline.svg'}}"></ion-icon>
		</div>
		<uno-title>
			{{(row | label:this.object) | translate}}
		</uno-title>
	</div>

	@if (this.expanded()) {
		<div class="uno-form-collapsable-field-container">
			<uno-form [editable]="this.editable" [layout]="row.fields" [object]="this.object"></uno-form>
		</div>

	}
}
