<div class="asset-tree-list-item-container" (click)="this.open();">
	<div class="asset-tree-list-item-space">

		<div class="asset-tree-list-item-flex">
			<!-- Level padding -->
			@for (item of (this.item.level | range); track item) {
				<div style="width: 22px; height: 50px;">
					<div class="asset-tree-list-item-guideline"></div>
				</div>
			}

			<!-- Expand arrrows -->
			@if (this.canExpand()) {
				<div style="width: 22px; height: 22px;">
					@if (this.item.expanded) {
						<uno-icon (click)="this.expand($event)" src="/assets/icons/uno/down-fullarrow.svg"></uno-icon>
					} @else {
						<uno-icon (click)="this.expand($event)" src="/assets/icons/uno/right-fullarrow.svg"></uno-icon>
					}
				</div>
			}

			<!-- Image -->
			<img style="border-radius: 50%;" width="34" height="34" loading="lazy" src="{{this.pictureUrl()}}" />

			<!-- Content -->
			<div class="asset-tree-list-item-content">
				@if (this.item.name) {
					<div class="uno-p2">{{this.item.name}}</div>}
				@if (this.item.tag) {
					<div class="uno-p3">{{this.item.tag}}</div>}
			</div>

		</div>
		<div class="asset-tree-list-item-flex">
			<!-- Actions -->
			@if (app.device.isDesktop()) {
				<ion-icon (click)="$event.stopPropagation(); generateQr(this.item.uuid);" name="qr-code-outline"></ion-icon>
				<ion-icon (click)="app.openInTab('/menu/asset-portfolio/asset/edit', {uuid: this.item.uuid}, $event);" name="desktop-outline"></ion-icon>
			}
		</div>

	</div>

</div>
