import {Component, OnInit} from '@angular/core';
import {UserPermissions} from '../../../../models/users/user-permissions';
import {ScreenComponent} from '../../../../components/screen/screen.component';
import {App} from '../../../../app';
import {MenuCardsGroup, MenuCardsGroupsComponent} from '../../../../components/layout/menu-cards-groups/menu-cards-groups.component';
import {Session} from '../../../../session';
import {UnoContentComponent} from '../../../../components/uno/uno-content/uno-content.component';

@Component({
	selector: 'master-data-page',
	templateUrl: 'master-data.page.html',
	standalone: true,
	imports: [UnoContentComponent, MenuCardsGroupsComponent]
})
export class MasterDataPage extends ScreenComponent implements OnInit {

	public app: any = App;

	public session: any = Session;

	public permissions = [];

	/**
	 * List of options in this menu.
	 */
	public groups: MenuCardsGroup[] = [
		{
			label: 'atexInspections',
			permissions: [UserPermissions.ATEX_INSPECTION],
			options: [
				{
					label: 'inspectionFields',
					icon: 'help',
					route: '/menu/atex/inspections/master-data/fields',
					data: null,
					permissions: [UserPermissions.ATEX_INSPECTION_FIELDS_EDIT],
					badge: null
				},
				{
					label: 'inspectionChecklists',
					icon: 'checkbox-outline',
					route: '/menu/atex/inspections/master-data/checklist',
					data: null,
					permissions: [UserPermissions.ATEX_INSPECTION_CHECKLIST_EDIT],
					badge: null
				},
				{
					label: 'settings',
					icon: 'settings',
					route: '/menu/atex/inspections/master-data/settings',
					data: null,
					permissions: [UserPermissions.ATEX_INSPECTION_SETTINGS_EDIT],
					badge: null
				}
			]
		},
		{
			label: 'assets',
			permissions: [UserPermissions.ASSET_PORTFOLIO],
			options: [
				{
					label: 'settings',
					icon: 'settings',
					route: '/menu/asset-portfolio/master-data/settings',
					data: null,
					permissions: [UserPermissions.ASSET_PORTFOLIO_SETTINGS_EDIT],
					badge: null
				},
				{
					label: 'fluidFamily',
					icon: 'shapes-outline',
					route: '/menu/asset-portfolio/master-data/fluid-family',
					data: null,
					permissions: [UserPermissions.ASSET_PORTFOLIO_FLUID_EDIT],
					badge: null
				},
				{
					label: 'fluidType',
					icon: 'shapes-outline',
					route: '/menu/asset-portfolio/master-data/fluid-type',
					data: null,
					permissions: [UserPermissions.ASSET_PORTFOLIO_FLUID_EDIT],
					badge: null
				}
			]
		},
		{
			label: 'ffp',
			permissions: [UserPermissions.FFP],
			options: [
				{
					label: 'recommendations',
					icon: 'hammer',
					route: '/menu/atex/ffp/master-data/recommendations',
					data: null,
					permissions: [UserPermissions.FFP_RECOMMENDATIONS_EDIT],
					badge: null
				}
			]
		},
		{
			label: 'actionPlan',
			permissions: [UserPermissions.ACTION_PLAN_ACTIONS_EDIT],
			options: [
				{
					label: 'actions',
					icon: 'hammer',
					route: '/menu/atex/action-plan/master-data/actions',
					data: null,
					permissions: [UserPermissions.ACTION_PLAN_ACTIONS_EDIT],
					badge: null
				}
			]
		},
		{
			label: 'repairs',
			permissions: [UserPermissions.REPAIR_NDT_EDIT, UserPermissions.REPAIR_WELDING_PROCEDURES_EDIT, UserPermissions.REPAIR_WELDING_TYPES_EDIT, UserPermissions.REPAIR_CONSUMABLES_EDIT],
			options: [
				{
					label: 'nonDestructiveTests',
					icon: 'hammer',
					route: '/menu/repairs/works/master-data/ndt',
					data: null,
					permissions: [UserPermissions.REPAIR_NDT_EDIT],
					badge: null
				},
				{
					label: 'weldingProcedures',
					icon: 'shapes-outline',
					route: '/menu/repairs/works/master-data/welding-procedures',
					data: null,
					permissions: [UserPermissions.REPAIR_WELDING_PROCEDURES_EDIT],
					badge: null
				},
				{
					label: 'weldingTypes',
					icon: 'shapes-outline',
					route: '/menu/repairs/works/master-data/welding-types',
					data: null,
					permissions: [UserPermissions.REPAIR_WELDING_TYPES_EDIT],
					badge: null
				},
				{
					label: 'weldingStandards',
					icon: 'shapes-outline',
					route: '/menu/repairs/works/master-data/welding-standards',
					data: null,
					permissions: [UserPermissions.REPAIR_WELDING_STANDARDS_EDIT],
					badge: null
				},
				{
					label: 'temporaryRepairType',
					icon: 'shapes-outline',
					route: '/menu/repairs/works/master-data/temporary-repair-types',
					data: null,
					permissions: [UserPermissions.REPAIR_TEMPORARY_TYPES_EDIT],
					badge: null
				},
				{
					label: 'consumables',
					icon: 'layers-outline',
					route: '/menu/repairs/works/master-data/consumables',
					data: null,
					permissions: [UserPermissions.REPAIR_CONSUMABLES_EDIT],
					badge: null
				},
				{
					label: 'settings',
					icon: 'settings',
					route: '/menu/repairs/works/master-data/settings',
					data: null,
					permissions: [UserPermissions.REPAIR_SETTINGS_EDIT],
					badge: null
				}
			]
		}
	];

	public ngOnInit(): void {
		super.ngOnInit();
		
		App.navigator.setTitle('masterdata');
	}
}
