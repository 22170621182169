import {EChartsType, init, use} from 'echarts/core';
import {LineChart, ScatterChart} from 'echarts/charts';
import {UniversalTransition} from 'echarts/features';
import {CSSUtils} from 'src/app/utils/css-utils';
import {TitleComponent, TooltipComponent, GridComponent, ToolboxComponent, MarkLineComponent, LegendComponent, DataZoomComponent, TimelineComponent} from 'echarts/components';
import {CanvasRenderer} from 'echarts/renderers';
import {StyleManager} from 'src/app/theme/style-manager';
import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';

import {UnoFormComponent} from '../../../../../../components/uno-forms/uno-form/uno-form.component';
import {FeatureTypeColor} from '../../../../../../models/pipeline-integrity/pipeline/feature-type';
import {ScreenComponent} from '../../../../../../components/screen/screen.component';
import {App} from '../../../../../../app';
import {UserPermissions} from '../../../../../../models/users/user-permissions';
import {Session} from '../../../../../../session';
import {Service} from '../../../../../../http/service';
import {ServiceList} from '../../../../../../http/service-list';
import {Locale} from '../../../../../../locale/locale';
import {UUID} from '../../../../../../models/uuid';
import {ChannelData} from '../../../../../../models/pipeline-integrity/mot/channel-data';
import {Experiment} from '../../../../../../models/pipeline-integrity/mot/experiment';
import {ResizeDetector} from '../../../../../../utils/resize-detector';
import {PipelineImport, SegmentData} from '../../../../data/mot/pipeline-import';
import {FormatDatePipe} from '../../../../../../pipes/format-date.pipe';
import {UnoTitleComponent} from '../../../../../../components/uno/uno-title/uno-title.component';
import {UnoContentComponent} from '../../../../../../components/uno/uno-content/uno-content.component';

// Register required components for echarts
use([TitleComponent, TooltipComponent, GridComponent, LineChart, DataZoomComponent, TimelineComponent, CanvasRenderer, LegendComponent, UniversalTransition, ScatterChart, MarkLineComponent, ToolboxComponent]);

@Component({
	selector: 'mot-acquisition-chart-comparison-page',
	templateUrl: 'mot-acquisition-chart-comparison.page.html',
	standalone: true,
	imports: [UnoContentComponent, UnoTitleComponent, TranslateModule, FormatDatePipe]
})
export class MOTAcquisitionChartComparisonPage extends ScreenComponent implements OnInit, OnDestroy {
	public app: any = App;

	public userPermissions: any = UserPermissions;

	public session: any = Session;

	@ViewChild('form', {static: false})
	public form: UnoFormComponent = null;

	public permissions = [UserPermissions.PIPELINE_INTEGRITY];

	/**
	 * Object with chart options.
	 */
	public chartOptions: any = {};

	/**
	 * Div to place the chart in.
	 */
	@ViewChild('chartDiv', {static: true})
	public chartDiv: ElementRef = null;

	/**
	 * EChart object used to plot and view the CMP information.
	 */
	public chart: EChartsType = null;

	/**
	 * UUID of the pipeline that belongs to the acquisition.
	 */
	public pipelineUuid: UUID = null;

	/**
	 * UUID of the CMP that belongs to the acquisition.
	 */
	public cmpUuid: UUID = null;

	/**
	 * List of experiments.
	 */
	public experiments: Experiment[] = [];

	/**
	 * Resize detector.
	 */
	public resizeDetector: ResizeDetector = null;

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();

		App.navigator.setTitle('acquisition');
		const data = App.navigator.getData();

		if (!data || !data.experiments || !data.cmpUuid || !data.pipelineUuid) {
			App.navigator.pop();
			return;
		}

		this.pipelineUuid = data.pipelineUuid;
		this.cmpUuid = data.cmpUuid;
		this.experiments = data.experiments;

		// Initialize e-chart
		this.initChart();
		await this.loadDataChart();

		this.resizeDetector = new ResizeDetector(this.chartDiv.nativeElement, () => {
			if (this.chart) {
				this.chart.resize();
			}
		});
	}

	/**
	 * Load channels data of the two experiments selected in the component from acquistion list screen. 
	 */
	public async loadDataChart(): Promise<void> {
		const channelData: ChannelData[][] = [];

		for (let i = 0; i < this.experiments.length; i++) {
			const requestChannel = await Service.fetch(ServiceList.pipelineIntegrity.mot.channel.getDataChannelsByExperiment, null, null, {experimentUuid: this.experiments[i].uuid}, Session.session);
			const channelDataEntries: ChannelData[] = [];
			for ( const data of requestChannel.response.channels) {
				channelDataEntries.push(ChannelData.parse(data));
			}
			channelData.push(channelDataEntries);
		}

		// Load object with distances between segments,cmp's and features.
		const pipelineData: SegmentData[] = await PipelineImport.loadPipelineData(this.pipelineUuid, this.cmpUuid);

		// Add data series for features/CMP's.
		const series: any = [];
		const markLine: any = [];

		for (let i = 0; i < pipelineData.length; i++) {
			for (const cmpD of pipelineData[i].cmps) {
				markLine.push({
					name: cmpD.name,
					xAxis: cmpD.distance,
					lineStyle: {color: CSSUtils.getVariable('--special-blue-1'), type: 'solid', width: 2},
					label: {position: 'end', show: 'true', formatter: '{b}'}
				});
			}

			for (const featD of pipelineData[i].features) {
				markLine.push({
					name: featD.description,
					xAxis: featD.distance,
					lineStyle: {color: CSSUtils.getVariable(FeatureTypeColor.get(featD.type)), type: 'solid', width: 2},
					label: {position: 'end', show: 'true', formatter: '{b}'}
				});
			}
		}
		series.push({
			type: 'line',
			markLine: {data: markLine, symbol: ['none', 'none']}
		});
		
		// Add data series for channels.
		const channelsLegend: string[] = [];

		for (let j = 0; j < channelData.length; j++) {
			for (let i = 0; i < channelData[j].length; i++) {
				const name: string = Locale.get('experiment') + ' ' + this.getLetter(j) + ' - ' + channelData[j][i].name;
				series.push({
					type: 'line',
					data: channelData[j][i].data,
					name: name,
					symbol: 'none',
					sampling: 'lttb,',
					tooltip: {valueFormatter: (value: number) => {return Math.round(value * 100) / 100;}}
				});
				channelsLegend.push(name);				
			}
		}

		this.chartOptions.legend.data = channelsLegend;
		this.chartOptions.series = series;
		this.chart.setOption(this.chartOptions);
	}

	/**
	 * Get experiment letter from its index.
	 */
	public getLetter(index: number): string {
		return String.fromCharCode('A'.charCodeAt(0) + index);
	}

	/**
	 * Initialize e-chart with default options.
	 * 
	 * Data and specific options are added on experiment selection.
	 */
	 public initChart(): void {
		this.chart = init(this.chartDiv.nativeElement, StyleManager.theme.echarts, {
			width: null,
			height: null,
			renderer: 'canvas',
			locale: Locale.code
		});
		
		// Add options to chart
		this.chartOptions = {
			title: {},
			tooltip: {trigger: 'axis'},
			legend: {},
			grid: {
				left: '3%',
				right: '4%',
				bottom: '3%',
				containLabel: true
			},
			xAxis: {type: 'value'},
			yAxis: {type: 'value'},
			dataZoom: [
				{type: 'slider'},
				{type: 'inside'}
			],
			series: []
		};

		this.chart.setOption(this.chartOptions);
	}


	/**
	 * Destroy chart and event manager.
	 */
	public ngOnDestroy(): void {
		if (this.chart) {
			this.chart.dispose();
			this.chart = null;
		}

		this.resizeDetector.destroy();
	}
}
