import {Locale} from 'src/app/locale/locale';
import {KeyValue} from '../../../../components/uno-input/uno-key-value/uno-key-value.component';
import {UUID} from '../../../../models/uuid';
import {UnoFormField} from '../../../../components/uno-forms/uno-form/uno-form-field';
import {UnoFormFieldTypes} from '../../../../components/uno-forms/uno-form/uno-form-field-types';
import {InspectionForm} from '../../../../models/inspections/form/inspection-form';
import {InspectionFormField, OptionsDataEntry} from '../../../../models/inspections/form/inspection-form-field';
import {InspectionFormFieldType} from './inspection-form-field-type';

/**
 * Inspection form utils contains tools to manipulate inspection form data.
 */
export class InspectionFormUtils {
	/**
	 * Correspondence between form field type and form types.
	 */
	public static types: Map<number, number> = new Map<number, number>([
		[InspectionFormFieldType.CHECKBOX, UnoFormFieldTypes.CHECKBOX],
		[InspectionFormFieldType.TEXT, UnoFormFieldTypes.TEXT_MULTILINE],
		[InspectionFormFieldType.IMAGES, UnoFormFieldTypes.IMAGE_RESOURCE_MULTIPLE],
		[InspectionFormFieldType.DOCUMENTS, UnoFormFieldTypes.DOCUMENT_RESOURCE_MULTIPLE],
		[InspectionFormFieldType.GEO_POSITION, UnoFormFieldTypes.GEO_POSITION],
		[InspectionFormFieldType.DATE, UnoFormFieldTypes.DATE],
		[InspectionFormFieldType.OPTIONS, UnoFormFieldTypes.OPTIONS],
		[InspectionFormFieldType.TEAM, UnoFormFieldTypes.TEAM_SELECTOR],
		[InspectionFormFieldType.COMPANY, UnoFormFieldTypes.COMPANY_SELECTOR],
		[InspectionFormFieldType.OPTIONS_MULTIPLE, UnoFormFieldTypes.OPTIONS_MULTIPLE],
		[InspectionFormFieldType.USER, UnoFormFieldTypes.USER_SELECTOR],

		// Title
		[InspectionFormFieldType.TITLE, UnoFormFieldTypes.TITLE],

		// Sub-form
		[InspectionFormFieldType.SUB_FORM, UnoFormFieldTypes.SUB_FORM],
		[InspectionFormFieldType.MULTIPLE_FORMS, UnoFormFieldTypes.REPETITIVE_FORM],
		[InspectionFormFieldType.COMPOSED_FIELD, UnoFormFieldTypes.COMPOSED_FIELD]
	]);

	/**
	 * Convert a inspection form field into a form field.
	 * 
	 * @param field - Inspection form field.
	 * @returns Form field to be presented on interface.
	 */
	public static createDynamicFormField(field: InspectionFormField): UnoFormField {
		const layoutField: UnoFormField = {
			editable: true,
			type: InspectionFormUtils.types.get(field.type),
			label: field.label + (field.label.length > 0 ? ' ' : '') + field.text,
			attribute: field.uuid
		};


		if (layoutField.type === UnoFormFieldTypes.OPTIONS || layoutField.type === UnoFormFieldTypes.OPTIONS_MULTIPLE) {
			layoutField.options = field.data.options.map((opt: OptionsDataEntry): {value: any, label: string} => {
				return {label: opt.value, value: opt.key};
			});

			
			layoutField.optionsDisplayMode = field.optionsDisplayMode;
			layoutField.sort = false;
		}

		return layoutField;
	}


	/**
	 * Create the form necessary to represent an inspection form with all its fields.
	 * 
	 * Receives a map of all inpection forms related by their UUIDs and the starting form UUID.
	 * 
	 * Returns the form fields and its sub-form fields for the provided form UUID. 
	 * 
	 * @param forms - Map of forms to retrieve forms by its UUID.
	 * @param formUuid - The form UUID being handled.
	 * @param editable - Indicates if the form fields are individually editable.
	 * @returns An array of form fields.
	 */
	public static buildDynamicForm(forms: Map<UUID, InspectionForm>, formUuid: UUID, editable: boolean = true): UnoFormField[] {
		const dynamicFields: UnoFormField[] = [];
		
		if (!forms.has(formUuid)) {
			throw new Error('Form ' + formUuid + ' not found');
		}

		const inspectionForm: InspectionForm = forms.get(formUuid);

		if (inspectionForm) {
			for (let i = 0; i < inspectionForm.fields.length; i++) {
				const field: InspectionFormField = inspectionForm.fields[i];
	
				const dynamicField: UnoFormField = {
					type: InspectionFormUtils.types.get(field.type),
					attribute: field.uuid,
					label: field.label + (field.label.length > 0 ? ' ' : '') + field.text,
					required: field.required,
					editable: editable
				};

				if (field.type === InspectionFormFieldType.OPTIONS || field.type === InspectionFormFieldType.OPTIONS_MULTIPLE) {
					if (field.data.options && field.data.options instanceof Array) {
						dynamicField.options = [];
						
						if (field.type === InspectionFormFieldType.OPTIONS) {
							dynamicField.options.push({label: Locale.get('empty'), value: null});
						}
						
						dynamicField.options = dynamicField.options.concat(field.data.options.map((d: KeyValue) => {
							return {label: d.value, value: d.key};
						}));
					} else {
						dynamicField.options = [];
					}

					dynamicField.optionsDisplayMode = field.optionsDisplayMode;
					dynamicField.sort = false;
				// Sub forms inspections
				} else if (field.isSubform()) {
					// Sub forms fields
					const subFormUuid: UUID = field.subFormUuid;
					if (subFormUuid) {
						dynamicField.fields = InspectionFormUtils.buildDynamicForm(forms, subFormUuid, editable);
					}
				}
	
				dynamicFields.push(dynamicField);
			}
		}

		return dynamicFields;
	}
}
