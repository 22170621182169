import {SortDirection} from 'src/app/utils/sort-direction';
import {Service} from 'src/app/http/service';
import {ServiceList} from 'src/app/http/service-list';
import {ServiceResponse} from 'src/app/http/service-response';
import {Session} from 'src/app/session';
import {UnoFormField} from 'src/app/components/uno-forms/uno-form/uno-form-field';
import {UnoFormFieldTypes} from 'src/app/components/uno-forms/uno-form/uno-form-field-types';
import {UserPermissions} from 'src/app/models/users/user-permissions';
import {InputOptionsMultipleBatchRequest, InputOptionsMultipleLazyPageRequest} from '../../../components/uno-input/uno-options-lazy/uno-options-lazy.component';


/**
 * Layout of the form used to show/edit roles.
 */
export const RoleFormLayout: UnoFormField[] = [
	{
		label: 'uuid',
		attribute: 'uuid',
		type: UnoFormFieldTypes.UUID
	},
	{
		required: true,
		label: 'name',
		attribute: 'name',
		type: UnoFormFieldTypes.TEXT
	},
	{
		label: 'description',
		attribute: 'description',
		type: UnoFormFieldTypes.TEXT_MULTILINE
	},
	{
		required: false,
		label: 'permissions',
		attribute: 'permissions',
		type: UnoFormFieldTypes.OPTIONS_MULTIPLE,
		options: function() {
			const options = [];

			for (const i in UserPermissions) {
				options.push({
					label: i,
					value: UserPermissions[i]
				});
			}
			
			return options;
		}()
	},
	{
		attribute: 'userUuids',
		label: 'users',
		type: UnoFormFieldTypes.OPTIONS_MULTIPLE_LAZY,
		showClear: true,
		required: false,
		multiple: true,
		identifierAttribute: 'uuid',
		isActive: function(object: any, row: UnoFormField) {
			return Session.user.isAdmin && !object.isAdmin;
		},
		fetchOptionsLazy: async function(request: InputOptionsMultipleLazyPageRequest, params: any): Promise<void> {
			const data = {
				from: request.from,
				count: request.count,
				search: request.search,
				sortField: '[user].[name]',
				sortDirection: SortDirection.ASC
			};

			try {
				const req: ServiceResponse = await Service.fetch(ServiceList.users.listName, null, null, data, Session.session);
				request.onFinish(req.response.users, req.response.hasMore, req.id);
			} catch {
				request.onError();
			}
		},
		fetchOptionsBatch: async function(request: InputOptionsMultipleBatchRequest, object: any): Promise<void> {
			const data = {users: request.options};

			try {
				const req: ServiceResponse = await Service.fetch(ServiceList.users.getBatch, null, null, data, Session.session);
				request.onFinish(req.response.users);
			} catch {}
		},
		getOptionText: function(option: any): string {
			return option.name;
		}
	}
];
