import {
	Component,
	ElementRef,
	OnDestroy,
	OnInit,
	ViewChild,
	ViewEncapsulation,
	WritableSignal,
	signal
} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Map, Marker, NavigationControl, Popup, ScaleControl} from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import StaticMode from '@mapbox/mapbox-gl-draw-static-mode';
import {MapboxStyleDefinition, MapboxStyleSwitcherControl} from 'mapbox-gl-style-switcher';
import {TranslateModule} from '@ngx-translate/core';
import {IonicModule} from '@ionic/angular';
import {PipelineService} from 'src/app/modules/pipeline-integrity/services/pipeline.service';
import {PlantsService} from 'src/app/modules/pipeline-integrity/services/plant.service';
import {LDSAlarmHistoryService} from 'src/app/modules/pipeline-integrity/services/lds-alarm.service';
import {LDSAlarmLevel} from 'src/app/models/pipeline-integrity/leak-detection/lds-alarm-level';
import {ScreenComponent} from '../../../../../../components/screen/screen.component';
import {App} from '../../../../../../app';
import {UserPermissions} from '../../../../../../models/users/user-permissions';
import {Session} from '../../../../../../session';
import {Service} from '../../../../../../http/service';
import {ServiceList} from '../../../../../../http/service-list';
import {Modal} from '../../../../../../modal';
import {Locale} from '../../../../../../locale/locale';
import {UnoFormModule} from '../../../../../../components/uno-forms/uno-form.module';
import {Pipeline} from '../../../../../../models/pipeline-integrity/pipeline/pipeline';
import {MapStyles, MapStylesLabel} from '../../../../../../theme/map-styles';
import {Geolocation} from '../../../../../../models/geolocation';
import {Environment} from '../../../../../../../environments/environment';
import {Segment} from '../../../../../../models/pipeline-integrity/pipeline/segment';
import {CMP} from '../../../../../../models/pipeline-integrity/mot/cmp';
import {MotCmpLayoutSimplified} from '../../../mot/cmp/mot-cmp-layout';
import {Feature} from '../../../../../../models/pipeline-integrity/pipeline/feature';
import {FeatureLayoutSimplified} from '../../feature-layout';
import {GeolocationUtils} from '../../../../../../utils/geolocation-utils';
import {CSSUtils} from '../../../../../../utils/css-utils';
import {FeatureType, FeatureTypeColor, FeatureTypeLabel} from '../../../../../../models/pipeline-integrity/pipeline/feature-type';
import {LDS} from '../../../../../../models/pipeline-integrity/leak-detection/lds';
import {LdsLayoutSimplified} from '../../../leak-detection/lds-layout';
import {Plant} from '../../../../../../models/pipeline-integrity/pipeline/plant';
import {ResizeDetector} from '../../../../../../utils/resize-detector';
import {UnoButtonComponent} from '../../../../../../components/uno/uno-button/uno-button.component';
import {PermissionsPipe} from '../../../../../../pipes/permissions.pipe';
import {CMPPID, LDSPID, FeaturePID, PipelineMapFeatureFilter, PointType} from '../../pipeline/edit/pipeline-edit.page';
import {PipelineLayout} from '../../pipeline/pipeline-layout';

@Component({
	selector: 'plant-map-page',
	templateUrl: 'plant-map.page.html',
	styleUrls: ['./plant-map.page.css'],
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [CommonModule, IonicModule, UnoFormModule, UnoButtonComponent, TranslateModule, PermissionsPipe]
})
export class PlantMapPage extends ScreenComponent implements OnInit, OnDestroy {
	public get app(): any {return App;}

	public get layout(): any {return PipelineLayout;}

	public get userPermissions(): any {return UserPermissions;}

	public get session(): any {return Session;}

	@ViewChild('map', {static: true})
	public mapDiv: ElementRef;

	@ViewChild('mapContainer', {static: true})
	public mapContainer: ElementRef;

	public permissions = [UserPermissions.PIPELINE_INTEGRITY];

	/**
	 * Indicates if the component is visible or not in the DOM tree.
	 *
	 * Used to keep track of the component state and refresh the screen state.
	 */
	public visible: boolean = false;

	/**
	 * Mapboxgl instance to display and control the map view.
	 */
	public map: Map = null;

	/**
	 * Mapbox draw instance to draw on map.
	 */
	public draw: MapboxDraw = null;

	/**
	 * Marker with the user GPS position.
	 */
	public marker: Marker = null;

	/**
	 * Current position in the map.
	 */
	public position: Geolocation = new Geolocation(0, 0);

	/**
	 * List of CMP's drawn on map
	 */
	public cmps: CMPPID[] = [];

	/**
	 * List of LDS drawn on map
	 */
	public lds: LDSPID[] = [];

	/**
	 * List of Features drawn on map
	 */
	public features: FeaturePID[] = [];

	/**
	 * List of segments drawn on map.
	 * 
	 * Used to check which segments are already drawn on map.
	 */
	public segments: Segment[] = [];

	/**
	 * Pipelines that belong to the plant.
	 */
	public pipelines: Pipeline[] = [];

	/**
	 * Plant being displayed on the map
	 */
	public plant: Plant = null;

	/**
	 * Resize detector.
	 */
	public resizeDetector: ResizeDetector = null;

	/**
	 * Legend entry to present in the map.
	 */
	public legend: WritableSignal<PipelineMapFeatureFilter[]> = signal([]);

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();
		

		this.resizeDetector = new ResizeDetector(this.mapContainer.nativeElement, () => {
			if (this.map) {
				this.map.resize();
			}
		});

		const data = App.navigator.getData();
		if (!data || !data.uuid) {
			App.navigator.pop();
			return;
		}

		this.plant = await PlantsService.get(data.uuid);
		this.pipelines = (await PipelineService.list({plantUuid: this.plant.uuid})).pipelines;
	
		App.navigator.setTitle(this.plant.name ? this.plant.name : 'map');

		this.createMap();
		
		// When plant has coordinates, zoom to position, default zoom to current position
		if (data.latitude && data.longitude) {
			this.updatePosition(new Geolocation(data.latitude, data.longitude));
		} else {
			this.updatePosition(await GeolocationUtils.getLocation());
		}
	}

	public ngOnDestroy(): void {
		super.ngOnDestroy();

		this.resizeDetector.destroy();

		if (this.map) {
			this.map.remove();
		}	
	}

	/**
	 * Create and configure the map instance.
	 */
	public createMap(): void {
		this.map = new Map({
			accessToken: Environment.MAPBOX_TOKEN,
			container: this.mapContainer.nativeElement,
			style: MapStyles.SATELLITE,
			zoom: 13,
			pitch: 0,
			bearing: 0,
			center: [this.position.longitude, this.position.latitude],
			attributionControl: false
		});


		// On load render segments, CMP's and features of Pipeline
		this.map.on('load', () => {
			this.loadPointData();		
		});

		this.initializeMapLayers();

		// Create a popup, but don't add it to the map yet.
		const popup = new Popup({
			closeButton: false,
			closeOnClick: false
		});

		this.map.on('mouseenter', 'feature-points.cold', (e) => {
			this.map.getCanvas().style.cursor = 'pointer';

			// Copy coordinates array.
			// @ts-ignore
			const coordinates = e.features[0].geometry.coordinates.slice();
			// @ts-ignore
			const description = e.features[0].properties.user_description;

			// Ensure that if the map is zoomed out such that multiple
			while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
				coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
			}

			// Populate the popup and set its coordinates based on the feature found.
			popup.setLngLat(coordinates).setHTML(description).addTo(this.map);
		});


		this.map.on('mouseleave', 'feature-points.cold', () => {
			this.map.getCanvas().style.cursor = '';
			popup.remove();
		});

		
		// If the user double clicks a element present a modal to edit it
		this.map.on('click', async(e) => {
			const selected = this.draw.getFeatureIdsAt(e.point);
			if (selected.length > 0) {
				const pid = selected[0];

				const points: {pid: any}[] = this.cmps.concat(this.features as any[]).concat(this.lds as any[]);
				for (let i = 0; i < points.length; i++) {
					const item = points[i];
					if (item.pid === pid) {
						try {
							// LDS
							if (item instanceof LDSPID) {
								const lds = structuredClone(item);
								await Modal.form(Locale.get('lds'), lds, LdsLayoutSimplified, [
									{
										success: true,
										label: 'open',
										color: 'primary',
										callback: (obj: any): void => {
											App.navigator.navigate('/menu/pipeline-integrity/lds/edit', {ldsUuid: obj.uuid});
										}
									},
									{
										success: true,
										label: 'graph',
										color: 'primary',
										callback: (obj: any): void => {
											App.navigator.navigate('/menu/pipeline-integrity/lds/chart', {ldsUuid: obj.uuid});
										}
									},
									{
										success: false,
										label: 'cancel',
										color: 'primary',
										callback: (obj: any): void => {}
									}], false);
								Object.assign(item, lds);
								// CMP
							} else if (item instanceof CMPPID) {
								const cmp = structuredClone(item);
								await Modal.form(Locale.get('cmp'), cmp, MotCmpLayoutSimplified, [
									{
										success: true,
										label: 'open',
										color: 'primary',
										callback: (obj: any): void => {
											App.navigator.navigate('/menu/pipeline-integrity/cmp/edit', {cmpUuid: obj.uuid});
										}
									},
									{
										success: true,
										label: 'acquisitions',
										color: 'primary',
										callback: (obj: any): void => {
											App.navigator.navigate('/menu/pipeline-integrity/acquisition/list', {cmpUuid: obj.uuid});
										}
									},
									{
										success: false,
										label: 'cancel',
										color: 'primary',
										callback: (obj: any): void => {}
									}], false);
								Object.assign(item, cmp);

								// Feature
							} else if (item instanceof FeaturePID) {
								const feature = structuredClone(item);
								await Modal.form(Locale.get('feature'), feature, FeatureLayoutSimplified);
								Object.assign(item, feature);
							}
						} catch (err) {}
					}
				}
			}
		});
	}

	/**
	 * Load segments, features and CMP from the API.
	 *
	 * Initialize structures required to draw them into the map.
	 */
	public async loadPointData(): Promise<void> {
		for (const pipeline of this.pipelines) {
			// Load segment data from API
			const requestSegments = await Service.fetch(ServiceList.pipelineIntegrity.pipeline.segment.list, null, null, {pipelineUuid: pipeline.uuid}, Session.session);
			
			
			const points: any[] = [];
			for (let j = 0; j < requestSegments.response.segments.length; j++) {
				const seg = Segment.parse(requestSegments.response.segments[j]);
				// First point
				if (j === 0) {
					points.push([seg.startPoint.longitude, seg.startPoint.latitude]);
				}

				// Draw line point
				points.push([seg.endPoint.longitude, seg.endPoint.latitude]);
				this.segments.push();
			}
			this.draw.add({
				type: 'Feature',
				geometry: {
					type: 'LineString',
					coordinates: points
				},
				properties: {pipeline: pipeline.uuid}
			});

			// Load CMP from API
			const requestCmp = await Service.fetch(ServiceList.pipelineIntegrity.mot.cmp.list, null, null, {pipelineUuid: pipeline.uuid}, Session.session);
			for (let j = 0; j < requestCmp.response.cmps.length; j++) {
				// Parse and draw CMP
				const cmp: CMP = CMP.parse(requestCmp.response.cmps[j]);
				this.draw.add({
					type: 'Feature',
					properties: {
						type: PointType.cmp + '_' + FeatureType.NONE,
						description: Locale.get('cmp') + ' (' + cmp.name + ')'
					},
					geometry: {
						type: 'Point',
						coordinates: [cmp.position.longitude, cmp.position.latitude]
					}
				});

				// Get pid of last drawn element
				const data = this.draw.getAll();
				const lastFeature = data.features.length - 1;
				const pid = data.features[lastFeature].id;

				// Add element to CMP
				const cmpPid = new CMPPID();
				Object.assign(cmpPid, cmp);
				cmpPid.pid = pid;
				this.cmps.push(cmpPid);
			}

			// Load LDS from API
			const requestLds = await Service.fetch(ServiceList.pipelineIntegrity.leakDetection.lds.list, null, null, {pipelineUuid: pipeline.uuid}, Session.session);
			for (let j = 0; j < requestLds.response.lds.length; j++) {
			// Parse and draw LDS's
				const lds: LDS = LDS.parse(requestLds.response.lds[j]);

				// Get alarm for the LDS
				const alarms = await LDSAlarmHistoryService.listAlarms(lds.uuid);
				let alarmLevel = null;
				for (const alarm of alarms) {
					if (alarm.activated) {
						if (alarmLevel === null || alarm.level > alarmLevel) {
							alarmLevel = alarm.level;
						}
					}
				}
			

				this.draw.add({
					type: 'Feature',
					properties: {
						type: PointType.lds + '_' + FeatureType.NONE,
						description: Locale.get('lds') + ' (' + lds.name + ')',
						alarm: alarmLevel
					},
					geometry: {
						type: 'Point',
						coordinates: [lds.position.longitude, lds.position.latitude]
					}
				});


				// Get pid of last drawn element
				const data = this.draw.getAll();
				const pid = data.features[data.features.length - 1].id;

				// Add element to LDS
				const ldsPid = new LDSPID();
				Object.assign(ldsPid, lds);
				ldsPid.pid = pid;
				ldsPid.alarmLevel = alarmLevel;
				this.lds.push(ldsPid);
			}

			// Load features from API
			const requestFeatures = await Service.fetch(ServiceList.pipelineIntegrity.pipeline.feature.list, null, null, {pipelineUuid: pipeline.uuid}, Session.session);
			for (let i = 0; i < requestFeatures.response.features.length; i++) {
				// Parse and draw features
				const feature = Feature.parse(requestFeatures.response.features[i]);
				this.draw.add({
					type: 'Feature',
					properties: {
						type: PointType.feature + '_' + feature.type,
						description: FeatureTypeLabel.get(feature.type) + ' (' + feature.name + ')'
					},
					geometry: {
						type: 'Point',
						coordinates: [feature.position.longitude, feature.position.latitude]
					}
				});

				// Get pid of last drawn element
				const drawData = this.draw.getAll();
				const lastFeature = drawData.features.length - 1;
				const pid = drawData.features[lastFeature].id;

				// Add element to FeaturePID
				const featurePid = new FeaturePID();
				Object.assign(featurePid, feature);
				featurePid.pid = pid;
				this.features.push(featurePid);
			}
		}
	}
	
	/**
	 * Initialize legend to be displayed on map.
	 *
	 * Configure styles and layers.
	 */
	public createLegend(): void {
		const legend: PipelineMapFeatureFilter[] = [];

		// CMP / LDS
		let layers = [Locale.get('cmp'), Locale.get('lds')];
		let colors = [CSSUtils.getVariable('--special-blue-1'), CSSUtils.getVariable('--special-blue-2')];
		const types = [PointType.cmp, PointType.lds];
		for (let i = 0; i < layers.length; i++) {
			legend.push(new PipelineMapFeatureFilter(layers[i], types[i], FeatureType.NONE, colors[i], true));
		}

		// Features
		layers = [...FeatureTypeLabel].slice(1).map(([key, val]) => {return Locale.get(val);});
		colors = [...FeatureTypeColor].slice(1).map(([key, val]) => {return CSSUtils.getVariable(val);});
		const featureTypes = Object.values(FeatureType).slice(1);
		for (let i = 0; i < layers.length; i++) {
			legend.push(new PipelineMapFeatureFilter(layers[i], PointType.feature, featureTypes[i], colors[i], false));
		}

		this.legend.set(legend);
	}

	/**
	 * Update legend, present the current legend selection.
	 * 
	 * Updates the filters applied in mapbox.
	 */
	public updateLegend(): void {
		const legend = this.legend();
		
		// Create list of features to hide
		const hiddenTypes = [];
		for (let i = 0; i < legend.length; i++) {
			if (!legend[i].enabled) {
				hiddenTypes.push(legend[i].type + '_' + legend[i].featureType);
			}
		}
	
		// Apply filters to hide features selected.
		const filter: any[] = [
			'all',
			['==', '$type', 'Point'],
			['==', 'meta', 'feature'],
			['==', 'active', 'false'],
			['!in', 'user_type', ...hiddenTypes]
		];

		this.map.setFilter('feature-points.cold', filter);
		this.map.setFilter('feature-points.hot', filter);
	}

	/**
	 * Toggle visibility of features on map.
	 *
	 * Set filter to features and strikethrough to legend map on click.
	 */
	public toggleLegendItem(type: PointType, featureType: number): void {
		const legend: PipelineMapFeatureFilter[] = this.legend();
		const item: PipelineMapFeatureFilter = legend.find(function(l: PipelineMapFeatureFilter) {
			return l.type === type && l.featureType === featureType;
		});

		if (!item) {
			throw new Error('Type ' + type + ',' + featureType + ' not available in legend list.');
		}

		item.enabled = !item.enabled;

		this.legend.set(legend);
		this.updateLegend();
	}

	/**
	 * Initialize the mapbox draw object.
	 *
	 * Configure styles used to draw elements into the map and controls.
	 */
	public initializeMapLayers(): void {
		// Draw Options
		this.draw = new MapboxDraw({
			defaultMode: 'static',
			userProperties: true,
			// Instead of showing all the draw tools, show only the line string and delete tools
			displayControlsDefault: false,
			controls: {
				// eslint-disable-next-line camelcase
				line_string: false,
				point: false,
				trash: false
			},
			boxSelect: false,
			keybindings: false,
			// @ts-ignore
			modes: Object.assign({static: StaticMode}, MapboxDraw.modes),
			// Set the draw mode to draw LineStrings by default.
			styles: this.getDrawingStyles()
		});
		

		// Marker
		this.marker = new Marker();
		this.marker.setLngLat([this.position.longitude, this.position.latitude]);
		this.marker.addTo(this.map); 

		// Render map legend
		this.createLegend();

		// Map search box
		this.map.addControl(new MapboxGeocoder({
			accessToken: Environment.MAPBOX_TOKEN,
			// @ts-ignore
			mapboxgl: this.map
		}), 'bottom-left');

		// Style switch controls
		const styles: MapboxStyleDefinition[] = [];
		MapStylesLabel.forEach(function(value, key) {
			styles.push({
				title: Locale.get(MapStylesLabel.get(key)),
				uri: key
			});
		});

		this.map.addControl(this.draw);
		this.map.addControl(new MapboxStyleSwitcherControl(styles), 'top-right');
		this.map.addControl(new NavigationControl(), 'top-right');
		this.map.addControl(new ScaleControl({maxWidth: 80, unit: 'metric'}));
		this.map.addControl(new ScaleControl({maxWidth: 80, unit: 'imperial'}));

		this.map.on('load', () => {
			this.updateLegend();
		});
	}

	/**
	 * Get drawing styles and rules for the pipeline and points.
	 * 
	 * @returns Drawing style to be applied.
	 */
	public getDrawingStyles(): any[] {
		const colorsFilter = [
			'case',
			['==', ['get', 'user_type'], PointType.feature + '_' + FeatureType.WELD], CSSUtils.getVariable('--special-yellow-2'), 
			['==', ['get', 'user_type'], PointType.feature + '_' + FeatureType.SUPPORT], CSSUtils.getVariable('--special-green-3'),
			['==', ['get', 'user_type'], PointType.feature + '_' + FeatureType.FLANGE], CSSUtils.getVariable('--special-purple-1'),
			['==', ['get', 'user_type'], PointType.feature + '_' + FeatureType.TEE_BRANCH], CSSUtils.getVariable('--special-green-1'),
			['==', ['get', 'user_type'], PointType.feature + '_' + FeatureType.ELBOW], CSSUtils.getVariable('--special-orange-1'),
			['==', ['get', 'user_type'], PointType.feature + '_' + FeatureType.BRACE], CSSUtils.getVariable('--special-red-2'),
			['==', ['get', 'user_type'], PointType.feature + '_' + FeatureType.DEFECT], CSSUtils.getVariable('--special-pink-1'),
			['==', ['get', 'user_type'], PointType.feature + '_' + FeatureType.INDICATION], CSSUtils.getVariable('--special-green-4'),
			['==', ['get', 'user_type'], PointType.feature + '_' + FeatureType.VALVES], CSSUtils.getVariable('--special-red-1'),
			['==', ['get', 'user_type'], PointType.feature + '_' + FeatureType.FEATURE_GENERIC], CSSUtils.getVariable('--special-yellow-1'),
			['==', ['get', 'user_type'], PointType.cmp + '_' + FeatureType.NONE], CSSUtils.getVariable('--special-blue-1'),
			['==', ['get', 'user_type'], PointType.lds + '_' + FeatureType.NONE], CSSUtils.getVariable('--special-blue-2'),
			CSSUtils.getVariable('--special-blue-2')
		];

		const borderColor = [
			'case',
			['==', ['get', 'user_alarm'], LDSAlarmLevel.HIGH], CSSUtils.getVariable('--error-normal'),
			['==', ['get', 'user_alarm'], LDSAlarmLevel.MEDIUM], CSSUtils.getVariable('--special-orange-1'),
			['==', ['get', 'user_alarm'], LDSAlarmLevel.LOW], CSSUtils.getVariable('--warning-normal'),
			CSSUtils.getVariable('--special-blue-2')
		];


		const borderWidth = [
			'case',
			['==', ['get', 'user_alarm'], LDSAlarmLevel.HIGH], 5,
			['==', ['get', 'user_alarm'], LDSAlarmLevel.MEDIUM], 4,
			['==', ['get', 'user_alarm'], LDSAlarmLevel.LOW], 3,
			0
		];


		const lineColor: any[] = ['case'];
		for (const pipeline of this.pipelines) {
			lineColor.push(['==', ['get', 'user_pipeline'], pipeline.uuid], pipeline.color);
		}
		lineColor.push(CSSUtils.getVariable('--special-blue-2'));

		return [
			// Set the line style
			{
				id: 'gl-draw-line',
				type: 'line',
				filter: [
					'all',
					['==', '$type', 'LineString']
				],
				layout: {
					'line-cap': 'round',
					'line-join': 'round'
				},
				paint: {
					'line-color': lineColor,
					'line-dasharray': [0.2, 2],
					'line-width': 4,
					'line-opacity': 0.7
				}
			},
			// Style the pipeline vertex points.
			{
				id: 'gl-draw-vertex',
				type: 'circle',
				filter: [
					'all',
					['==', '$type', 'Point'],
					['!=', 'meta', 'feature']
				],
				paint: {
					'circle-radius': 7,
					'circle-color': CSSUtils.getVariable('--special-blue-2'), // this.pipeline.color || CSSUtils.getVariable('--special-blue-2'),
					'circle-stroke-color': CSSUtils.getVariable('--light'),
					'circle-stroke-width': 3
				}
			},
			// Style applied to features
			{
				id: 'feature-points',
				type: 'circle',
				filter: [
					'all',
					['==', '$type', 'Point'],
					['==', 'meta', 'feature'],
					['==', 'active', 'false']],
				paint: {
					'circle-color': colorsFilter,
					'circle-radius': 7,
					'circle-stroke-color': borderColor,
					'circle-stroke-width': borderWidth
				}
			}
		];
	}

	/**
	 * Update map position.
	 */
	 public updatePosition(location: Geolocation): void {
		this.position = location;

		if (this.map) {
			this.map.flyTo({center: [location.longitude, location.latitude]});
		}

		this.marker.setLngLat([location.longitude, location.latitude]);
	}


}
