<uno-content>
	<!-- Dashboard -->
	@if ([userPermissions.REPAIR_STATUS_GRAPH, userPermissions.PERM_REPAIR_CRITICALITY_GRAPH] | hasPermissions) {
		<ion-card-content>
			<ion-grid>
				<ion-row>
					@if (userPermissions.REPAIR_STATUS_GRAPH | hasPermissions) {
						<ion-col size="12" size-md="6">
							<graph-repairs-states></graph-repairs-states>
						</ion-col>
					}
					@if (userPermissions.REPAIR_CRITICALITY_GRAPH | hasPermissions) {
						<ion-col size="12" size-md="6">
							<graph-repair-criticality></graph-repair-criticality>
						</ion-col>
					}
				</ion-row>
			</ion-grid>
		</ion-card-content>
	}

	<!-- Menus -->
	<menu-cards-groups [groups]="this.groups"></menu-cards-groups>
</uno-content>
